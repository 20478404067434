define("flaneur/services/filestack", ["exports", "filestack-js", "flaneur/config/environment"], function (_exports, _filestackJs, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const _excluded = ["maxFileSize"];

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  class ImageUploadError extends Error {
    constructor(message, response) {
      super(message);
      this.response = response;
    }

    toString() {
      return `${this.message}: ${JSON.stringify(this.response)}`;
    }

  }

  class FilestackService extends Ember.Service {
    constructor() {
      super(...arguments);
      this.client = _filestackJs.default.init(_environment.default.filestack.apiKey);
      this.client.on('upload.error', this.uploadError.bind(this));
      this.pickerResolve = null;
      this.pickerReject = null;
    }

    attempToLoadImage(imageData, attemptCount = 1, outerResolve = null, outerReject = null) {
      let promise = new Promise((resolve, reject) => {
        const triggerReject = message => {
          console.error(`${message} (attempt ${attemptCount})`);
          reject(new ImageUploadError(message, imageData));
        };

        const img = new Image();

        const cleanup = () => {
          clearTimeout(tid);
          img.onload = null;
          img.onerror = null;
        };

        let tid = setTimeout(() => {
          cleanup();
          triggerReject('Image reloading from Imgix reached timeout (20s)');
        }, 20 * 1000);

        img.onload = () => {
          cleanup();

          if (img.height && img.width) {
            resolve(imageData);
            return;
          }

          triggerReject('Unable to reload image from Imgix');
        };

        img.onerror = () => {
          cleanup();
          triggerReject('Unable to reload image from Imgix');
        };

        img.src = imageData.url;
      });

      if (outerResolve) {
        promise = promise.then(outerResolve);
      }

      if (outerReject) {
        promise = promise.catch(outerReject);
      }

      return promise;
    }

    uploadError(event) {
      console.error('An upload error occurred:', event);

      if (this.pickerReject) {
        this.pickerReject(new Error('Failed to upload any files'));
        this.pickerReject = null;
      }
    }

    getStorageOptions(mixin = {}) {
      const {
        filestack: filestackConfig
      } = _environment.default;

      const storageOptions = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, filestackConfig.path ? {
        path: filestackConfig.path
      } : {}), filestackConfig.container ? {
        container: filestackConfig.container
      } : {}), filestackConfig.location ? {
        location: filestackConfig.location
      } : {}), filestackConfig.region ? {
        region: filestackConfig.region
      } : {}), mixin);

      return storageOptions;
    }

    openPicker(optionsOverride = {}) {
      return new Promise((resolve, reject) => {
        // because filestack can trigger errors outside of the promise
        this.pickerResolve = resolve;
        this.pickerReject = reject;
        const storeTo = this.getStorageOptions();

        const {
          maxFileSize
        } = optionsOverride,
              supportedOverrides = _objectWithoutProperties(optionsOverride, _excluded);

        const options = _objectSpread({
          onUploadDone: async result => {
            var _result$filesUploaded, _result$filesFailed;

            this.pickerResolve = null;
            this.pickerReject = null;
            const filesUploaded = [];
            const filesFailed = []; // whilst filestack may say things have uploaded we need to
            // convert each URL to an imgix URL, and verify we can load

            if ((_result$filesUploaded = result.filesUploaded) !== null && _result$filesUploaded !== void 0 && _result$filesUploaded.length) {
              const results = await Promise.allSettled(result.filesUploaded.map(this.onUploadResultsPerImage.bind(this)));
              results.forEach(result => {
                if (result.status === 'fulfilled') {
                  filesUploaded.push(result.value);
                } else {
                  filesFailed.push(result.reason);
                }
              });
            }

            if ((_result$filesFailed = result.filesFailed) !== null && _result$filesFailed !== void 0 && _result$filesFailed.length) {
              filesFailed.push(...result.filesFailed);
            }

            resolve({
              filesUploaded,
              filesFailed
            });
          },
          storeTo,
          accept: ['image/*'],
          fromSources: ['local_file_system'],
          acceptFn: file => {
            if (maxFileSize && file.size > maxFileSize) {
              throw new Error('File is too large, select something smaller than 5Mb');
            }
          },
          maxFiles: 1,
          uploadInBackground: false
        }, supportedOverrides);

        this.client.picker(options).open();
      });
    }

    onUploadResultsPerImage(res) {
      /* eslint-disable prettier/prettier */
      return new Promise((resolve, reject) => {
        const {
          filestack: filestackConfig
        } = _environment.default;

        if (!res.url || !res.filename || !res.mimetype || !res.size) {
          console.error(JSON.stringify(res));
          reject(new Error('Invalid file response'));
        }

        if (res.status !== 'Stored') {
          console.error(JSON.stringify(res));
          reject(new Error('Failed to upload file'));
        }

        const {
          url
        } = res;
        const regex = /^https:\/\/cdn\.filestackcontent\.com\/(.+)$/;
        const parts = url.match(regex);

        if (!parts || !parts[1]) {
          throw new Error('Invalid file URL');
        }

        const imgixUrl = `${filestackConfig.imgixPrefix}${parts[1]}`;
        const response = {
          url: imgixUrl,
          originalUrl: url,
          filename: res.filename,
          mimetype: res.mimetype,
          size: res.size,
          status: res.status
        }; // first attempt, this can resolve straight away

        return this.attempToLoadImage(response, 0, resolve).catch(async () => {
          let isResolved = false;
          let resolvedValue = null;

          const doNothing = () => {};

          const markResolved = v => {
            isResolved = true;
            resolvedValue = v;
          }; // try over a period of 3 seconds to load the image
          // this gives us a chance each second to succeed
          // if we reach the end however...


          for (let i = 1; i < 4; i++) {
            await delay(1000);
            await this.attempToLoadImage(response, i, markResolved, doNothing);

            if (isResolved) {
              resolve(resolvedValue);
              return resolvedValue;
            }
          } // we try one last time to load, at this point we
          // we have tried 5 times (0 -> 4) and we end the promise
          // one way or another


          await delay(1000);
          await this.attempToLoadImage(response, 4, resolve, reject);
        });
      });
    }

    uploadFile(file, onProgress) {
      const token = {};
      const uploadOptions = {
        onProgress: progress => {
          if (onProgress) {
            onProgress(progress.totalPercent);
          }
        }
      };
      const storageOptions = this.getStorageOptions({
        filename: file.name
      });
      return this.client.upload(file, uploadOptions, storageOptions, token).then(this.onUploadResultsPerImage.bind(this)).catch(error => {
        console.error(`Failed to upload file: ${error}`);
        throw error;
      });
    }

  }

  _exports.default = FilestackService;
});