define("flaneur/pods/components/image-uploader/component", ["exports", "@glimmer/component", "ember-component-css/pod-names"], function (_exports, _component, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ImageUploaderComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class ImageUploaderComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "filestack", _descriptor, this);

      _initializerDefineProperty(this, "notify", _descriptor2, this);

      _initializerDefineProperty(this, "isUploading", _descriptor3, this);

      _initializerDefineProperty(this, "multiple", _descriptor4, this);

      _initializerDefineProperty(this, "files", _descriptor5, this);

      _defineProperty(this, "maxFileSize", 5000000);

      _defineProperty(this, "fileTypes", 'image/*');

      _defineProperty(this, "fileTypesRegExp", new RegExp('^image/.*$', 'i'));
    }

    get usePicker() {
      var _this$args$usePicker;

      return (_this$args$usePicker = this.args.usePicker) !== null && _this$args$usePicker !== void 0 ? _this$args$usePicker : false;
    }

    get styleNamespace() {
      return _podNames.default['image-uploader'];
    }

    get hasImages() {
      var _this$files;

      return (this === null || this === void 0 ? void 0 : (_this$files = this.files) === null || _this$files === void 0 ? void 0 : _this$files.length) > 0;
    }

    setInputElement(element) {
      this.inputElement = element.querySelector('input');
    }

    mapImageStackResponse(resource) {
      return {
        url: resource.url,
        width: resource.width,
        height: resource.height,
        mimetype: resource.mimetype,
        size: resource.size
      };
    }
    /**
     * Handle file selection is triggered by the native file input. This is not used
     * when the filestack picker is in use.
     */


    async handleFileSelection(event) {
      return this.validateFiles(Array.from(event.target.files));
    }

    openUploadDialog() {
      // use filestack picker
      if (this.usePicker) {
        const options = {
          accept: ['image/*'],
          maxFileSize: this.maxFileSize
        };
        this.filestack.openPicker(options).then(response => {
          var _response$filesUpload, _response$filesFailed;

          if ((_response$filesUpload = response.filesUploaded) !== null && _response$filesUpload !== void 0 && _response$filesUpload.length) {
            const formattedResults = response.filesUploaded.map(this.mapImageStackResponse.bind(this));

            if (this.multiple) {
              var _this$args$onUpload, _this$args;

              (_this$args$onUpload = (_this$args = this.args).onUpload) === null || _this$args$onUpload === void 0 ? void 0 : _this$args$onUpload.call(_this$args, formattedResults);
            } else {
              var _this$args$onUpload2, _this$args2;

              (_this$args$onUpload2 = (_this$args2 = this.args).onUpload) === null || _this$args$onUpload2 === void 0 ? void 0 : _this$args$onUpload2.call(_this$args2, formattedResults[0]);
            }
          }

          if ((_response$filesFailed = response.filesFailed) !== null && _response$filesFailed !== void 0 && _response$filesFailed.length) {
            var _response$filesUpload2;

            console.error(response.filesFailed);
            const msg = (_response$filesUpload2 = response.filesUploaded) !== null && _response$filesUpload2 !== void 0 && _response$filesUpload2.length ? 'Some files failed to upload. Please try again.' : 'All files failed. Please try again.';
            this.notify.show('error', msg, 8000);
          }
        });
        return;
      } // use native file input


      this.inputElement.click();
    }

    onDropzoneDragOver(event) {
      event.preventDefault();
    }
    /**
     * Dropzone needs to use the native upload path (which still uses filestack,
     * but doesn't use the picker). This is not used by the filestack picker, or its
     * dropzone handling.
     */


    async onDropzoneDrop(event) {
      event.preventDefault();
      await this.validateFiles(Array.from(event.dataTransfer.files));
    }
    /**
     * Validate files is triggered by the native file input. This is not used
     * when the filestack picker is in use.
     */


    async validateFiles(files) {
      if (!files || !files.length) {
        return;
      }

      const filteredFiles = files.filter(file => file.size <= this.maxFileSize && file.type.match(this.fileTypesRegExp));

      if (filteredFiles.length !== files.length) {
        const join = (...arr) => arr.join('');

        this.notify.show('error', join("Some images you're trying to upload are the wrong type ", 'or they exceed the maximum size.', '<br /><br />', 'Please keep your files below 5MB and using a supported image ', 'format, e.g. PNG, JPEG, etc.'), 12000);
        return;
      }

      return this.uploadFiles(filteredFiles);
    }
    /**
     * This method is used by the native file selector route, and the drag and drop
     * route. For the filestack picker route, look to the openUploadDialog method.
     * This is not used when the filestack picker is in use.
     */


    async uploadFiles(files) {
      if (!files || !files.length) {
        throw new Error('No files to upload');
      }

      this.isUploading = true;
      this.files = files.map(file => ({
        file,
        progress: 0
      }));

      try {
        const uploads = files.map((file, index) => this.filestack.uploadFile(file, progress => {
          this.files[index].progress = progress;
        }));
        const results = await Promise.all(uploads);
        const formattedResults = results.map(this.mapImageStackResponse);

        if (this.multiple) {
          var _this$args$onUpload3, _this$args3;

          (_this$args$onUpload3 = (_this$args3 = this.args).onUpload) === null || _this$args$onUpload3 === void 0 ? void 0 : _this$args$onUpload3.call(_this$args3, formattedResults);
        } else {
          var _this$args$onUpload4, _this$args4;

          (_this$args$onUpload4 = (_this$args4 = this.args).onUpload) === null || _this$args$onUpload4 === void 0 ? void 0 : _this$args$onUpload4.call(_this$args4, formattedResults[0]);
        }
      } catch (error) {
        console.error('File upload failed:', error);
        this.notify.show('error', 'File upload failed. Please try again.', 4000);
      } finally {
        this.isUploading = false;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "filestack", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isUploading", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "multiple", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "files", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setInputElement", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setInputElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleFileSelection", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "handleFileSelection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openUploadDialog", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "openUploadDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDropzoneDragOver", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onDropzoneDragOver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDropzoneDrop", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onDropzoneDrop"), _class.prototype)), _class));
  _exports.default = ImageUploaderComponent;
});